import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
// import { resetOrderDetailsData, fetchPatientOrderDetail, fetchPatientOrders } from 'patientOrderSupport/common/actions';
import { dateTimeStringToMMSTimeFormat, dateTimeOnly } from 'utils/dateUtils';
import {
  fetchAllPatients,
  resetPatientDetailsData,
  fetchPatientDetail,
} from 'traditionalSiteApp/common/actions';
import { sideBarMenu } from './configs';

const usePatientSupportDashboardViewModel = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);

  const fetchTabData = () => {
    let activeTabId = locSearch.get('active_tab_id') || 1;
    let activeTabKey = sideBarMenu.find(
      menu => menu.id === parseInt(activeTabId)
    );
    return {
      activeTabId: parseInt(activeTabId),
      activeTabKey: activeTabKey.key,
    };
  };

  const pagination = useSelector(
    store => store.traditionalSiteReducer.pagination
  );
  // const pagination  = null;

  const [activeTab, setActiveTab] = useState(fetchTabData());
  const [page, setPage] = useState(1);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showPatientDetailsModal, setShowPatientDetailsModal] = useState(false);

  const fetchOrderDetails = id =>
    dispatch(fetchPatientDetail(id, () => setShowArchiveModal(true)));

  const fetchPatientDetailsOnly = id =>
    dispatch(fetchPatientDetail(id, () => setShowPatientDetailsModal(true)));

  const all_patients = useSelector(store => {
    return store.traditionalSiteReducer.all_patients.map(patient => {
      return {
        ...patient,
        dateRegistered: dateTimeOnly(patient.createdAt),
        dateArchived: dateTimeOnly(patient.dateArchived),
        onClick: () => fetchOrderDetails(patient.id),
        patientDetailOnclick: () => fetchPatientDetailsOnly(patient.id),
      };
    });
  });
  const isLoading = useSelector(
    store => store.traditionalSiteReducer.isLoading
  );

  const siteName = useSelector(store => store.traditionalSiteReducer.siteName);

  const onArchiveModalHide = () => {
    setShowArchiveModal(false, () => {
      dispatch(resetPatientDetailsData());
    });
  };

  const onPatientDetailsModalHide = () => {
    setShowPatientDetailsModal(false, () => {
      dispatch(resetPatientDetailsData());
    });
  };

  const updateActiveTabData = activeTabId => {
    history.push(`${location.pathname}?active_tab_id=${activeTabId}`);
  };

  useEffect(() => {
    sideBarMenu.map(menu => {
      if (menu.isDefault) updateActiveTabData(menu.id);
    });
  }, []);

  useEffect(() => {
    setActiveTab(fetchTabData());
  }, [locSearch.get('active_tab_id')]);

  useEffect(() => {
    dispatch(fetchAllPatients({ archived: activeTab.activeTabKey }));
  }, [activeTab.activeTabId]);

  const empty_message = useSelector(
    store => store.traditionalSiteReducer.empty_message
  );

  return {
    activeTab,
    updateActiveTabData,
    pagination,
    isLoading,
    siteName,
    showArchiveModal,
    onArchiveModalHide,
    setShowArchiveModal,
    all_patients,
    empty_message,
    showPatientDetailsModal,
    onPatientDetailsModalHide,
    setShowPatientDetailsModal,
  };
};

export default usePatientSupportDashboardViewModel;

export var activeTab;
