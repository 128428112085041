import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import Pagination from 'components/Pagination';
import { useSelector, useDispatch } from 'react-redux';
import './data-table.scss';
import useDataTableViewModel from './viewModel';
import Login from '../../../containers/Login';
import { getFormatedPhoneNumber } from 'utils/stringUtils';
import { dateTimeOnly } from 'utils/dateUtils';

const DataTable = props => {
  const { dataTableHeaders, getHeaderColumn, data, pagination } =
    useDataTableViewModel(props);
  return (
    <div className='data-table-wrapper'>
      <table className='table-wrapper'>
        <thead className='table-header'>
          <tr className='table-row'>
            {dataTableHeaders.map(header => {
              return getHeaderColumn(header, props.activeTab.activeTabKey);
            })}
          </tr>
        </thead>
        <tbody className='table-body'>
          {data.map(patient => {
            return (
              <tr
                className={`table-row ${data.rowHighlight ? 'highlight' : ''}`}
              >
                <td
                  className='table-full-name'
                  onClick={() => patient.patientDetailOnclick(patient.id)}
                >
                  {patient.fullName ? patient.fullName : 'N/A'}
                </td>
                <td>
                  {patient.contactNumber && patient.contactNumber !== ''
                    ? getFormatedPhoneNumber(patient.contactNumber)
                    : 'N/A'}
                </td>
                <td>{patient.email ? patient.email : 'N/A'}</td>
                <td>
                  {patient.dateRegistered ? patient.dateRegistered : 'N/A'}
                </td>
                {props.activeTab.activeTabKey === 'inbox' ? (
                  <td>
                    {(patient.softHandoffFlag === '' || patient.softHandoffFlag === null || patient.softHandoffFlag === undefined) ? (
                        ''
                      ) : (<>
                        <Icon icon={patient.softHandoffFlag ? "fluent-mdl2:completed" : "ic:outline-cancel"}  width="25" height="25" style={patient.softHandoffFlag ? {color: 'green'} : {color: 'red'}}  />
                        &nbsp; {patient.softHandoffDisplay}
                      </>)
                    }
                  </td>
                ) : (
                  <></>
                )}
                {props.activeTab.activeTabKey === 'inbox' ? (
                  <td>
                    {patient.softHandoffDate ? dateTimeOnly(patient.softHandoffDate) : ''}
                  </td>
                ) : (
                  <></>
                )}
                {props.activeTab.activeTabKey === 'inbox' ? (
                  <td>
                    <button
                      className='btn btn-primary'
                      onClick={() => patient.onClick(patient.id)}
                    >
                      Contacted
                    </button>
                  </td>
                ) : (
                  <></>
                )}
                {props.activeTab.activeTabKey === 'archive' ? (
                  <td>
                    {patient.archivedBy ? patient.archivedBy : 'N/A'}
                  </td>
                ) : (
                  <></>
                )}
                {props.activeTab.activeTabKey === 'archive' ? (
                  <td>{patient.dateArchived ? patient.dateArchived : 'N/A'}</td>
                ) : (
                  <></>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className='pagination-wrapper'>
        <Pagination
          pagination={pagination}
          title='Patients'
          handlePagination={props.handlePagination}
        />
      </div>
    </div>
  );
};

export default DataTable;
